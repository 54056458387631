window.addEventListener('DOMContentLoaded', () => {
    function mapServerToAlias(url) {
        const serverNumber = url.match(/n(\d+)/)[1];
        const aliases = {
          '15': 'Pizza',
          '16': 'Burger',
          '17': 'Nugget',
          '18': 'Fries',
          '19': 'Meatballs',
          '20': 'Milkshake',
          '21': 'Kebab™',
          '22': 'Taquito',
          '23': 'Nachos',
          '24': 'Ramen',
          '25': 'Wiener',
        };
        return aliases[serverNumber];
      }

      // Function to display the server name in the UI
      function displayServerName(serverName) {
          const maxSizeElement = document.getElementById('maxSize');
          if (maxSizeElement) {
              const message = document.createElement('div');
              message.textContent = serverName;
              message.style.fontSize = '14px';
              maxSizeElement.parentNode.insertBefore(message, maxSizeElement.nextSibling);
          } else {
              console.warn('Element with id "maxSize" not found.');
          }
      }

       //Event listener for when nodeApi is set
       document.addEventListener('nodeApiSet', function() {
         // Ensure there's a short delay after the event
         setTimeout(function() {
             if (page.nodeApi) {
                 const alias = mapServerToAlias(page.nodeApi);
                 const serverName = `${alias} (${page.nodeApi.match(/n\d+/)[0]})`;
                 displayServerName(serverName);
             }
         }, 0);
       });
})